export const API_CONNECTION = {
	
	URL: 'https://admin-api.hey-jee.com/',
}

export const SYSTEM_CONST = {

	USER_FRONT_URL: 'https://www.hey-jee.com/',
}

if(-1 < window.location.href.indexOf('://dev.admin.hey-jee.com')) {

	API_CONNECTION.URL = 'https://dev.admin-api.hey-jee.com/';
	SYSTEM_CONST.USER_FRONT_URL = 'https://dev.hey-jee.com/';
}
if(-1 < window.location.href.indexOf('://localhost')) {

	API_CONNECTION.URL = 'http://localhost:8800/';
	SYSTEM_CONST.USER_FRONT_URL = 'http://localhost:3000/';
}

export const LANG_MODE = 'ja';

export const JA_LANG = {

	ADDR01: '市区町村'
	, ADDR02: '番地'
	, ADDR03: '建物名・部屋番号'



	, CONFIRM_DELETE_TITLE: '本当に削除してもいいですか？'
	, CONFIRM_USER_DELETE_BODY: 'ユーザーはログインができなくなります。 もしもの場合、システム管理者による復元は可能です。'

	, CONFIRM_CHANGE_PLAN_STATUS_TITLE: '本当にステータスを変更してもいいですか？'
	, CONFIRM_CHANGE_PLAN_STATUS_BODY: 'ステータス変更後も次回更新までは現在の契約が維持されます。'


	, ENTER_ONLY_FOR_UPDATE: '更新の場合のみ入力'



	, ERR_INVALID_OPERATION : '不正な画面遷移です'
	, ERR_UNEXPECTED : '予期せぬエラーが発生しました'

	, ERR_LINK : 'セキュリティ上、必ず下記から画面を再読み込み行なってください。'


}
