import {
  Close,
  Delete,
  Done,
  Favorite,
  FavoriteBorder,
  Label,
  LockOutlined,
} from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  TextareaAutosize,
  Toolbar,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { api, isset } from "../util/util";
import { API_CONNECTION, JA_LANG } from "../config/Const";
import { useRecoilState } from "recoil";
import { showLoaderAtom } from "../store/atom";
import { useSnackbar } from "notistack";
import ConfirmDialog from "../component/ConfirmDialog";
import SubscriptionList from "./SubscriptionList";

function CreatorEditor({
  closeFunc,
  creatorId = 0,
  handleChangePage,
  creatorListPage,
}) {
  const { enqueueSnackbar } = useSnackbar();

  let isEdit = creatorId !== 0;

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [showConfirm, setShowConfirm] = useState(false);

  const [formData, setFormData] = useState({
    id: 0,
    nickname: "",
    email: "",
    profile_txt: "",
    password: "",
    tfs_id: "",
    production_branch_id: "",
    created_at: "",
    platform_type: [],
  });

  const [listData, setListData] = useState({});

  const [errorList, setErrorList] = useState({});

  //初期処理
  useEffect(() => {
    isEdit && setShowLoader(true);
    api({ url: `${API_CONNECTION.URL}api/creators/${creatorId}` })
      .then((res) => {
        if (isEdit) {
          setFormData(res.data.creator);
        }
        initListData(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });

    isEdit = creatorId !== 0;
  }, [creatorId]);

  const initListData = (loadData) => {
    const addListData = {};

    for (let loadKey in loadData) {
      if (loadKey.match(/^arr/)) {
        addListData[loadKey] = loadData[loadKey];
      }
    }

    if (addListData.length !== 0) {
      setListData({ ...listData, ...addListData });
    }
  };

  const handleForm = (event) => {

    if (event.target.name === "platform_type") {
      const updateForm = formData.platform_type;
      const targetValue = parseInt(event.target.value);

      if (event.target.checked) {
        if (updateForm.includes(targetValue)) {
        } else {
          updateForm.push(targetValue);
        }
      } else {
        if (updateForm.includes(targetValue)) {
          updateForm.splice(updateForm.indexOf(targetValue), 1);
        } else {
        }
      }

      setFormData({...formData, [event.target.name] : updateForm});
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});

    setShowLoader(true);

    let apiUrl = `${API_CONNECTION.URL}api/creators`;
    let apiMethod = "post";

    if (isEdit) {
      apiUrl = `${API_CONNECTION.URL}api/creators/${formData.id}`;
      apiMethod = "put";
    }

    api({
      url: apiUrl,
      type: apiMethod,
      data: formData,
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          if (handleChangePage) {
            handleChangePage(
              null,
              isEdit ? (creatorListPage ? creatorListPage : 0) : 0
            );
          }
          closeFunc();
          enqueueSnackbar(
            isEdit
              ? "Creator update successfully!"
              : "Creator regist successfully!",
            {
              variant: "success",
            }
          );
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleDelete = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});
    setShowConfirm(false);

    setShowLoader(true);

    api({
      url: `${API_CONNECTION.URL}api/creators/${formData.id}`,
      type: "delete",
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          if (handleChangePage) {
            handleChangePage(null, creatorListPage ? creatorListPage : 0);
          }
          closeFunc();
          enqueueSnackbar("Creator delete successfully!", {
            variant: "success",
          });
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  return (
    <Fragment key={creatorId}>
      <AppBar sx={{ position: "relative" }} color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeFunc}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {creatorId ? "Creator : " + formData.nickname : "Regist Creator"}
          </Typography>

          <Button
            autoFocus
            color="secondary"
            variant="contained"
            startIcon={<Done />}
            onClick={handleSubmit}
          >
            SAVE
          </Button>
          {isEdit && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Delete />}
              size="small"
              sx={{ ml: "10px" }}
              onClick={() => {
                setShowConfirm(true);
                console.dir(formData);
              }}
            >
              Delete
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth="100%" component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <input type="hidden" name="id" value="{formData.id}" />
            <Grid container justify="center" spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: "100%" }}>
                      {/* <FormLabel>Production</FormLabel> */}
                      <InputLabel
                        required
                        shrink
                        htmlFor="production_branch_id"
                      >
                        Production
                      </InputLabel>

                      <Select
                        native
                        defaultValue=""
                        id="production_branch_id"
                        label="Production"
                        name="production_branch_id"
                        value={formData.production_branch_id}
                        onChange={handleForm}
                      >
                        <option aria-label="" value="">
                          ---
                        </option>
                        {isset(listData.arrProduction) &&
                          listData.arrProduction.length !== 0 &&
                          Object.keys(listData.arrProduction).map(
                            (productionId) => (
                              <optgroup
                                label={listData.arrProduction[productionId]}
                                key={productionId}
                              >
                                {isset(listData.arrProductionBranch) &&
                                  listData.arrProductionBranch.length !== 0 &&
                                  isset(
                                    listData.arrProductionBranch[productionId]
                                  ) &&
                                  Object.keys(
                                    listData.arrProductionBranch[productionId]
                                  ).map((branchId) => (
                                    <option value={branchId} key={branchId}>
                                      {listData.arrProduction[productionId] +
                                        " - " +
                                        listData.arrProductionBranch[
                                          productionId
                                        ][branchId]}
                                    </option>
                                  ))}
                              </optgroup>
                            )
                          )}
                      </Select>
                    </FormControl>
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.production_branch_id)
                          ? ""
                          : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.production_branch_id}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <FormLabel>Nickname</FormLabel> */}
                    <TextField
                      required
                      fullWidth
                      id="nickname"
                      label="Nickname"
                      name="nickname"
                      autoComplete="nickname"
                      value={formData.nickname}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.nickname) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.nickname}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <FormLabel>Email</FormLabel> */}
                    <TextField
                      required
                      fullWidth
                      type="email"
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      value={formData.email}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{ display: isset(errorList.email) ? "" : "none" }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.email}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <FormLabel>Password</FormLabel> */}
                    <TextField
                      required={creatorId ? false : true}
                      fullWidth
                      name="password"
                      label={
                        "Password " +
                        (creatorId ? "※" + JA_LANG.ENTER_ONLY_FOR_UPDATE : "")
                      }
                      type="password"
                      id="password"
                      value={formData.password}
                      onChange={handleForm}
                      autoComplete="new-password"
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.password) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.password}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      id="tfs_id"
                      label="ID in 246"
                      name="tfs_id"
                      autoComplete="ID in 246"
                      value={formData.tfs_id}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{ display: isset(errorList.tfs_id) ? "" : "none" }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.tfs_id}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="profile_txt"
                      label="Profile Text"
                      value={formData.profile_txt}
                      multiline
                      maxRows={100}
                      onChange={handleForm}
                      sx={{ minWidth: "100%" }}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.profile_txt) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.profile_txt}
                    </Alert>
                  </Grid>
                  <hr />
                  <Grid item xs={12}>
                    <div style={{ marginLeft: "10px" }}>
                      <InputLabel shrink>PlatForm</InputLabel>
                      {isset(listData.arrPlatform) &&
                        Object.keys(listData.arrPlatform).map(
                          (platformType) => (
                            <FormControlLabel
                              key={platformType}
                              label={listData.arrPlatform[platformType]}
                              onChange={handleForm}
                              control={
                                <Checkbox
                                  size="small"
                                  icon={<FavoriteBorder />}
                                  checkedIcon={<Favorite />}
                                  name="platform_type"
                                  value={platformType}
                                  checked={
                                    formData.platform_type.includes(
                                      parseInt(platformType)
                                    )
                                      ? true
                                      : false
                                  }
                                />
                              }
                            />
                          )
                        )}
                    </div>
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.platform_type) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.platform_type}
                    </Alert>
                  </Grid>
                  {isEdit && (
                    <Grid item xs={12}>
                      <div style={{ marginLeft: "10px" }}>
                        <InputLabel shrink>Created at</InputLabel>
                        {formData.created_at}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {isEdit && (
                <Grid item xs={12} md={6}>
                  <SubscriptionList creatorId={creatorId} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Container>
      <ConfirmDialog
        open={showConfirm}
        okFunc={handleDelete}
        closeFunc={(e) => {
          setShowConfirm(false);
        }}
        titleText={JA_LANG.CONFIRM_DELETE_TITLE}
        bodyText={JA_LANG.CONFIRM_USER_DELETE_BODY}
      />
    </Fragment>
  );
}

export default CreatorEditor;
