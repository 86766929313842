import React, { useEffect } from "react";

import {
  Link,
  Outlet,
  useLoaderData,
  useMatches,
  useNavigate,
  useOutlet,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentMenuAtom, loginInfoAtom, showLoaderAtom } from "./store/atom";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Dialog, Menu, MenuItem, Slide } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  MicExternalOn as MicExternalOnIcon,
  Business as BusinessIcon,
  Key as KeyIcon,
  Diversity1 as Diversity1Icon,
  Logout as LogoutIcon,
} from "@mui/icons-material/";
import { api, isset } from "./util/util";
import { API_CONNECTION } from "./config/Const";
import AccountEditor from "./page/AccountEditor";
import { AnimatePresence } from "framer-motion";

const drawerWidth = 240;

// メニュー設定
const menus = [
  {
    label: "Creator",
    url: "/creator",
    icon: MicExternalOnIcon,
    name: "creator",
  },
  {
    label: "Fan",
    url: "/fan",
    icon: Diversity1Icon,
    name: "fan",
  },
  {
    label: "Production",
    url: "/production",
    icon: BusinessIcon,
    name: "production",
  },
  {
    label: "Account",
    url: "/account",
    icon: KeyIcon,
    name: "account",
  },
  { label: "Logout", url: "/logout", icon: LogoutIcon, name: "logout" },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RouterApp() {
  const matches = useMatches();
  const outlet = useOutlet();
  const navigate = useNavigate();

  const loadData = useLoaderData().data;

  const theme = useTheme();
  const [loginInfo, setLoginInfo] = useRecoilState(loginInfoAtom);
  const [currentMenu, setCurrentMenu] = useRecoilState(currentMenuAtom);
  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const [accountEditorOpen, setAccountEditorOpen] = React.useState(false);

  //初期処理
  useEffect(() => {
    setShowLoader(false);
    if (isset(loadData.info)) {
      if (
        !isset(loadData.info) ||
        !isset(loadData.info.id) ||
        !loadData.info.id
      ) {
        window.location.href = "/login";
      }
      setLoginInfo(loadData.info);
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccountEditorClose = () => {
    setAccountEditorOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} color="secondary">
          <Toolbar
            x={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h5"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, fontWeight: "bold" }}
            >
              Heyjee!
            </Typography>
            {auth && (
              <div>
                {loginInfo.name}
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  sx={{ textAlign: "right" }}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      setAccountEditorOpen(true);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem onClick={() => {navigate('/logout')}}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menus.map((gmenu) => {
              const Gicon = gmenu.icon;

              return (
                <Link
                  key={gmenu.name}
                  to={gmenu.url}
                  style={{ textDecoration: "none", color: "initial" }}
                >
                  <ListItem
                    key={gmenu.label}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      selected={currentMenu === gmenu.name}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <Gicon />
                      </ListItemIcon>
                      <ListItemText
                        primary={gmenu.label}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              );
            })}
          </List>
          <Divider />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {/* <Outlet /> */}
          {/* <AnimatePresence> */}
          <React.Fragment key={matches[1].pathname}>{outlet}</React.Fragment>
          {/* </AnimatePresence> */}
        </Box>
      </Box>
      <Dialog
        fullScreen
        open={accountEditorOpen}
        onClose={handleAccountEditorClose}
        TransitionComponent={Transition}
      >
        <AccountEditor
          open={accountEditorOpen}
          closeFunc={handleAccountEditorClose}
          accountId={loginInfo.id}
        />
      </Dialog>
    </>
  );
}

export default RouterApp;
