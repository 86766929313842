import React, { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { api, isset } from "../util/util";
import { useRecoilState } from "recoil";
import { currentMenuAtom, showLoaderAtom } from "../store/atom";
import { API_CONNECTION, SYSTEM_CONST } from "../config/Const";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Container,
  Dialog,
  Fab,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  AccountCircle,
  Add,
  Search,
  Settings,
  Link as LinkIcon,
} from "@mui/icons-material";
import CreatorEditor from "./CreatorEditor";
import UrlDialog from "../component/UrlDialog";

const columns = [
  { id: "id", label: "ID", align: "left" },
  { id: "nickname", label: "NICKNAME", align: "left" },
  { id: "email", label: "EMAIL", align: "left" },
  { id: "production_name", label: "PRODUCTION", align: "left" },
  { id: "branch_name", label: "BRANCH", align: "left" },
  { id: "created_at", label: "CREATED", align: "left" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Creator() {
  const navigate = useNavigate();

  const loadData = useLoaderData().data;

  const [currentMenu, setCurrentMenu] = useRecoilState(currentMenuAtom);

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [creatorList, setCleatorList] = useState([]);
  const [creatorCount, setCleatorCount] = useState(0);
  const [creatorListPage, setCleatorListPage] = useState(0);
  const [creatorListRows, setCleatorListRows] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const [searchInput, setSearchInput] = useState("");

  const [formData, setFormData] = useState({});
  const [errorList, setErrorList] = useState({});

  const [editorOpen, setEditorOpen] = useState(false);
  const [urlOpen, setUrlOpen] = useState(false);

  const [editId, setEditId] = useState(0);

  const [urlInfos, setUrlInfos] = useState([]);

  const [listData, setListData] = useState({});

  const useEffectInit = useRef(false);

  const listSetting = (listdata) => {
    setCurrentMenu("creator");
    setCleatorList(listdata.creators);
    setCleatorCount(listdata.allCount);
    setCleatorListPage(listdata.page);
    setCleatorListRows(listdata.rowsPerPage);
    setOrder(listdata.order);
    setOrderBy(listdata.orderBy);
  };

  //初期処理
  useEffect(() => {
    setCurrentMenu("creator");

    if (
      isset(loadData.searchSet) &&
      isset(loadData.searchSet.searchInput) &&
      loadData.searchSet.searchInput
    ) {
      setSearchInput(loadData.searchSet.searchInput);
    }

    listSetting(loadData);
    initListData(loadData);
  }, []);

  //検索窓監視
  useEffect(() => {
    if (useEffectInit.current === false) {
      useEffectInit.current = true;
      return;
    }

    const timer = setTimeout(() => {
      const sInputQuery = encodeURI(searchInput);

      setShowLoader(true);
      api({
        url: `${API_CONNECTION.URL}api/creators?page=0&searchInput=${sInputQuery}`,
      })
        .then((res) => {
          listSetting(res.data);
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  const initListData = (loadData) => {
    const addListData = {};

    for (let loadKey in loadData) {
      if (loadKey.match(/^arr/)) {
        addListData[loadKey] = loadData[loadKey];
      }
    }

    if (addListData.length !== 0) {
      setListData({ ...listData, ...addListData });
    }
  };

  const handleForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    api({ url: `${API_CONNECTION.URL}api/creators?page=${newPage}` })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((err) => {});
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsVal = parseInt(event.target.value, 10);
    setShowLoader(true);
    api({ url: `${API_CONNECTION.URL}api/creators?page=0&rows=${rowsVal}` })
      .then((res) => {
        listSetting(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const handleChangeSort = (columnName) => {
    const selected = columnName === orderBy;
    api({
      url: `${API_CONNECTION.URL}api/creators?page=0&order_by=${columnName}&order=${order}&selected=${selected}`,
    })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((res) => {});
  };

  const handleEditorOpen = (creatorId) => {
    setEditId(creatorId);
    setEditorOpen(true);
  };

  const handleEditorClose = () => {
    setEditorOpen(false);
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ mt: 4, mb: 4 }}>
        <h2>Creator</h2>
        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          sx={{ position: "absolute", top: 80, right: 15 }}
          onClick={() => {
            handleEditorOpen(0);
          }}
        >
          <Add />
        </Fab>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel htmlFor="input-with-icon-adornment">
                Seach Text
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                value={searchInput}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: window.innerHeight }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        // <TableCell
                        //   key={column.id}
                        //   align={column.align}
                        //   sx={{ fontWeight: "bold" }}
                        // >
                        //   {column.label}
                        // </TableCell>

                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ fontWeight: "bold" }}
                          sortDirection={orderBy === column.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : "asc"}
                            onClick={() => handleChangeSort(column.id)}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell key="edit" align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {creatorList.map((creator) => {
                      return (
                        <TableRow hover role="checkbox" key={creator.id}>
                          {columns.map((column) => {
                            const value = creator[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                          <TableCell key="edit" align="left">
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              size="small"
                              onClick={(e) => {
                                handleEditorOpen(creator.id);
                              }}
                              sx={{
                                textAlign: "center",
                                padding: "0",
                                mr: "10px",
                              }}
                            >
                              <Settings />
                            </IconButton>
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              size="small"
                              onClick={(e) => {
                                setUrlOpen(true);
                                if (
                                  isset(creator.creator_platform) &&
                                  creator.creator_platform
                                ) {
                                  const updateUrlInfo = [];

                                  creator.creator_platform.map(
                                    (creator_platform) => {
                                      updateUrlInfo.push({
                                        url: SYSTEM_CONST.USER_FRONT_URL + 'f/' + creator_platform.url_code,
                                        label:
                                          isset(listData.arrPlatform) &&
                                          isset(
                                            listData.arrPlatform[
                                              creator_platform.platform_type
                                            ]
                                          )
                                            ? listData.arrPlatform[
                                                creator_platform.platform_type
                                              ]
                                            : "",
                                      });
                                      return true;
                                    }
                                  );

                                  setUrlInfos(updateUrlInfo);
                                } else {
                                  setUrlInfos([]);
                                }
                              }}
                              sx={{ textAlign: "center", padding: "0" }}
                            >
                              <LinkIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={loadData.arrPageRowNum}
                component="div"
                count={creatorCount}
                rowsPerPage={creatorListRows}
                page={creatorListPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        fullScreen
        open={editorOpen}
        onClose={handleEditorClose}
        TransitionComponent={Transition}
      >
        <CreatorEditor
          closeFunc={handleEditorClose}
          creatorId={editId}
          key={editId} //子を初期化させるために必要
          handleChangePage={handleChangePage}
          creatorListPage={creatorListPage}
        />
      </Dialog>
      <UrlDialog
        open={urlOpen}
        setOpen={setUrlOpen}
        title={
          urlInfos.length
            ? "Creator page URL by platform"
            : "No platform registration"
        }
        urlInfos={urlInfos}
      />
    </>
  );
}

/*
function Profile() {
  return <h2>記事2</h2>;
}

*/

export default Creator;
