import React, { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { api, isset } from "../util/util.js";
import { useRecoilState } from "recoil";
import { currentMenuAtom, showLoaderAtom } from "../store/atom.js";
import { API_CONNECTION, JA_LANG } from "../config/Const.js";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import ConfirmDialog from "../component/ConfirmDialog.js";
import { enqueueSnackbar } from "notistack";

function SubscriptionList({ creatorId = 0, fanId = 0 }) {
  const columns = [
    { id: "plan_name", label: "PLAN", align: "left" },
    { id: "expires", label: "EXPIRES", align: "left" },
    { id: "status", label: "STATUS", align: "left" },
    { id: "created_at", label: "CREATED", align: "left" },
  ];

  if (creatorId) {
    columns.unshift({ id: "fan_name", label: "FAN", align: "left" });
  }
  if (fanId) {
    columns.unshift({ id: "creator_name", label: "CREATOR", align: "left" });
  }

  const navigate = useNavigate();

  const loadData = useLoaderData().data;

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscriptionCount, setSubscriptionCount] = useState(0);
  const [subscriptionListPage, setSubscriptionListPage] = useState(0);
  const [subscriptionListRows, setSubscriptionListRows] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const [formData, setFormData] = useState({});
  const [errorList, setErrorList] = useState({});

  const [listData, setListData] = useState({});

  //複数対象に対応するためIDで持つ
  const [showConfirm, setShowConfirm] = useState(0);

  const useEffectInit = useRef(false);

  const listSetting = (listdata) => {
    setSubscriptionList(listdata.subscriptions);
    setSubscriptionCount(listdata.allCount);
    setSubscriptionListPage(listdata.page);
    setSubscriptionListRows(listdata.rowsPerPage);
    setOrder(listdata.order);
    setOrderBy(listdata.orderBy);
  };

  //初期処理
  useEffect(() => {
    api({
      url: `${API_CONNECTION.URL}api/subscriptions?creator_id=${creatorId}&fan_id=${fanId}`,
    })
      .then((res) => {
        listSetting(res.data);
        initListData(res.data);

        //初期でやらないと初期値入らない
        initInputData(res.data.subscriptions);
      })
      .catch((err) => {});
  }, [creatorId, fanId]);

  //リスト更新時
  useEffect(() => {
    initInputData(subscriptionList);
  }, [subscriptionList]);

  const initListData = (loadData) => {
    const addListData = {};

    for (let loadKey in loadData) {
      if (loadKey.match(/^arr/)) {
        addListData[loadKey] = loadData[loadKey];
      }
    }

    if (addListData.length !== 0) {
      setListData({ ...listData, ...addListData });
    }
  };

  const initInputData = (subscriptions) => {
    const addInputData = {};

    subscriptions.map((subscription) => {
      addInputData["subs_status_" + subscription.id] = subscription.status;
    });

    if (addInputData.length !== 0) {
      setFormData({ ...formData, ...addInputData });
    }
  };

  const handleForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    api({
      url: `${API_CONNECTION.URL}api/subscriptions?creator_id=${creatorId}&fan_id=${fanId}&page=${newPage}`,
    })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((err) => {});
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsVal = parseInt(event.target.value, 10);
    setShowLoader(true);
    api({
      url: `${API_CONNECTION.URL}api/subscriptions?creator_id=${creatorId}&fan_id=${fanId}&page=0&rows=${rowsVal}`,
    })
      .then((res) => {
        listSetting(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const handleChangeSort = (columnName) => {
    const selected = columnName === orderBy;
    api({
      url: `${API_CONNECTION.URL}api/subscriptions?creator_id=${creatorId}&fan_id=${fanId}&page=0&order_by=${columnName}&order=${order}&selected=${selected}`,
    })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((res) => {});
  };

  const handleUpdate = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    console.dir(formData);

    const subscriptionId = showConfirm;

    setErrorList({});
    setShowConfirm(0);

    setShowLoader(true);

    api({
      url: `${API_CONNECTION.URL}api/subscriptions/status/${subscriptionId}`,
      type: "put",
      data: {
        id: subscriptionId,
        status: formData["subs_status_" + subscriptionId],
      },
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          enqueueSnackbar("Subscription update successfully!", {
            variant: "success",
          });
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleUpdateCancel = (event) => {
    handleChangePage(null, subscriptionListPage);
    setShowConfirm(0);
  };

  return (
    <>
      <Container maxWidth="100%">
        <Grid container>
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: window.innerHeight }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <Typography variant="subtitle1" gutterBottom>
                          {creatorId ? "Subscription Fans" : "Follow Creators"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sortDirection={orderBy === column.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : "asc"}
                            onClick={() => handleChangeSort(column.id)}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptionList.map((subscription) => {
                      return (
                        <TableRow hover role="checkbox" key={subscription.id}>
                          {columns.map((column) => {
                            const value = subscription[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "status" ? (
                                  <FormControl
                                    variant="standard"
                                    sx={{ m: 1, minWidth: 120 }}
                                  >
                                    <Select
                                      labelId={"subs_status_" + subscription.id}
                                      id={"subs_status_" + subscription.id}
                                      name={"subs_status_" + subscription.id}
                                      value={
                                        formData[
                                          "subs_status_" + subscription.id
                                        ]
                                      }
                                      onChange={(e) => {
                                        handleForm(e);
                                        setShowConfirm(subscription.id);
                                      }}
                                      label="Age"
                                    >
                                      <MenuItem value="">
                                        <em>---</em>
                                      </MenuItem>
                                      {isset(listData.arrSubsStatus) &&
                                        Object.keys(listData.arrSubsStatus).map(
                                          (statusId) => (
                                            <MenuItem
                                              value={statusId}
                                              key={statusId}
                                            >
                                              {listData.arrSubsStatus[statusId]}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                  </FormControl>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={loadData.arrPageRowNum}
                component="div"
                count={subscriptionCount}
                rowsPerPage={subscriptionListRows}
                page={subscriptionListPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ConfirmDialog
        open={showConfirm ? true : false}
        okFunc={(e) => {
          handleUpdate(e);
        }}
        closeFunc={(e) => {
          handleUpdateCancel(e);
        }}
        titleText={JA_LANG.CONFIRM_CHANGE_PLAN_STATUS_TITLE}
        bodyText={JA_LANG.CONFIRM_CHANGE_PLAN_STATUS_BODY}
      />
    </>
  );
}

export default SubscriptionList;
